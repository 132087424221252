/* flex */
.flexbox{display:flex}
.flex1{flex:1}
.direct_c{flex-direction: column !important;}
.direct_r{flex-direction: row !important;}
.noMargin{margin: 0 !important;}
.spbetween{justify-content: space-between}
.spcenter{justify-content: center}
.flexend{justify-content: flex-end}
.alignc{align-items: center}
.flexwrap{flex-wrap:wrap}
.flex-item{flex-grow: 0;flex-shrink: 0;}
.layout_wrap {display: grid;height: 100vh;grid-template-columns: 180px auto;grid-template-rows: 40px auto;}
.layout_wrap_view {display: flex;height: 100vh;flex-direction: column;overflow: hidden;z-index: 100;}

/*.header_wrap  background: #52618a; color: #fff;*/
.header_wrap {border-bottom:1px solid #ddd;grid-column: 1 / 3;grid-row: 1 / 2;background: #fff;color: #333;font-weight:800;padding: 10px;display: flex;justify-content: space-between;z-index: 100;}
.header_wrap .mainLink {color: inherit;text-decoration: none;}
.header_wrap button {background: none;margin-left: 10px;}
.header_wrap button svg {fill: #fff;width: 19px;height: 19px;}
.header_wrap .header_right {display: flex;}
.header_wrap .header_right svg {width: 18px;height: 18px;margin: 0 5px;}

.side_menu_wrap {grid-column: 1 / 2;grid-row: 2 / 3;background: #1c2b56;}
.side_menu_wrap ul li a {color: #ccc;display: flex;align-items: center;justify-content: space-between;padding: 10px 15px;text-decoration: none;}
.side_menu_wrap ul li a div.menu_item_arrow{height: 12px;display: flex;align-items: flex-start;justify-content: center;}
.side_menu_wrap ul li a div.menu_item_title{/* width: 100px; */ flex: 1; padding: 0 10px;}
.side_menu_wrap ul li > a.active {color: #fff;position: relative}
.side_menu_wrap ul li > a.active:before{position: absolute;left:0;width:6px;height:100%;background:#6476f5;top:0;content:''}
.side_menu_wrap ul li > a.active svg {fill: #fff;}
.side_menu_wrap ul li > .sub_menu_wrap{border-left:6px solid #6476f5;background: #0b121f;transition: max-height 0.3s ease-out;max-height: 0;overflow: hidden;}
.side_menu_wrap ul li > .sub_menu_wrap.active{max-height: 500px;}
.side_menu_wrap ul li > .sub_menu_wrap ul.menu_sub_list {padding:10px;color: #ccc;display: flex;flex-direction: column;justify-content: space-between;text-decoration: none;font-size: 12px;height: 100%}
.side_menu_wrap ul li > .sub_menu_wrap.active ul.menu_sub_list li a{padding: 10px 12px}
.side_menu_wrap ul li > .sub_menu_wrap ul.menu_sub_list li a.active {border-radius:7px;background: #6677a6;color: #fff;border:none}
.side_menu_wrap ul li > .sub_menu_wrap ul.menu_sub_list li a.active:before{display:none}
.side_menu_wrap ul li > .sub_menu_wrap ul.menu_sub_list li a.active svg {fill: #fff;}

/*로그아웃 사용자 환경설정 드롭다운 메뉴*/
.dropdown {position: relative;}
.dropdown:after {content: "";position: absolute;top: 0;right: 0;border-left: 8px solid transparent;border-right: 8px solid transparent;border-top: 8px solid transparent;width: 25px;height: 25px;}
.login_menu {position: absolute;top: 100%;right: -10px;background: #fff;list-style-type: none;padding: 0;margin-top: 9px;opacity: 0;pointer-events: none;max-height: 0;overflow: hidden;transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;}
.person_btn:hover + .login_menu,
.dropdown:hover .login_menu {opacity: 1;pointer-events: auto;max-height: 100px;}
.login_menu li a {display: inline-block;padding: 8px;width: 150px;background-color: #484e59;text-align: center;cursor: pointer;}
.login_menu li:hover a {background-color: #707177;color: #fff;}

.contents_wrap {background:#f6f7fb;overflow: hidden;grid-column: 2 / 3;grid-row: 2 / 3;flex: 1;}
.contents_wrap > div{padding:20px;}

/* .title_wrap{margin-bottom:10px;} */
.title_wrap h3{color:#333;font-size:15px; line-height: 34px;}

/* d */
.content_container{width: 100%; height: 100%; display: flex; flex-direction: column; padding:20px; gap: 15px;}


/* SearchBar */
.search_wrap{display:flex;margin-bottom:15px;justify-content: flex-start;align-items: center;gap: 5px}
.search_wrap .search_group{display:flex;gap: 5px !important;}
.search_wrap .search_group dl{display:flex;flex-direction: column}
.search_wrap .search_group dl dt{font-size:13px;font-weight:600;margin-bottom:5px;color:#333}
.search_wrap .search_group dl dd input{max-width:140px;background:#eaebef;height:34px;line-height:34px; padding: 15px 10px;border:none;border-radius:7px}
.search_wrap .search_group dl dd select{width:100px;background:#eaebef;height:34px;line-height:34px}

/* .search_wrap{display:flex;background:#fff;padding: 10px;margin-bottom:15px;} */
.search_wrap  dl{display:flex;flex-direction: column}
.search_wrap  dl dt{font-size:13px;font-weight:600;margin-bottom:5px;color:#333}
.search_wrap  dl dd input{max-width:140px;border-bottom:1px solid #333;background:none;height:20px;line-height:20px}
.search_wrap  dl dd select{width:100px;border-bottom:1px solid #333;background:none;height:20px;line-height:20px}

.btn_basic {opacity:.8;padding: 0 11px;margin: 2px;background: #606060;height:32px;line-height:32px; color: #fff;border-radius:5px}
.btn_basic:disabled{background: #d9d9d9;color: #7f8c8d;}
.btn_basic:disabled:hover{opacity: 0.5 !important;}
.btn_basic:hover{opacity: 1 !important}
.btn_basic.blue {background: #6476f5 !important;color: #fff;}
.btn_basic.black {background: #000000 !important;color: #fff;}
.btn_basic.btn_search {background: #1fcf9b !important;color: #fff;}
.btn_basic.blue:disabled, .btn_basic.black:disabled, .btn_basic.btn_search:disabled{ background: #d9d9d9!important;color: #7f8c8d;}
.list_button {padding: 3px 6px;border-radius: 3px; color: #fff;}
.list_button.assign {background: #d3d3d3;}
.list_button.assign:hover {opacity:1;background: #5d5d5d;}
.list_button.emergency {background: #e11d48;}

/* 데이트픽커 추가 */
/* #searchBar .dateContainer{position: relative; height: 21px;}
#searchBar .dateContainer .date_input{width: 110px;}
#searchBar .dateCalendar{position: absolute;z-index: 2;background: #fff;padding: 5px 20px 10px 20px;border: 1px solid #333;top:34px;border-radius:8px}
#searchBar .dateCalendar .date_range_select{}
#searchBar .dateCalendar .date_range_select button{
  border: 1px solid #ddd;border-radius: 20px; padding: 2px 4px;margin-right: 5px;background-color: #f3f3f3;color: #000;font-weight: 600;
}
#searchBar .rdp-nav button{width: 20px; height: 20px;}
#searchBar .rdp-nav button svg {width: 20px; height: 20px;}
#searchBar .rdp-chevron {fill: #787878; width: 14px; height: 14px;}
#searchBar .rdp-selected .rdp-day_button {background: #6a6a6a; color: #fff; border: none; font-size: 15px;}
#searchBar .rangeCalendar .rdp-selected .rdp-day_button{background: none; color: #6a6a6a;}
#searchBar .rdp-today:not(.rdp-outside) {color: #6476f5;}
#searchBar .rdp-day_button{width: 30px; height: 30px;font-size: 13.2px;}
#searchBar .rdp-selected {font-size: 16px;}
#searchBar .rdp-caption_label, #searchBar .rdp-dropdown_root{font-size: 13.5px;}
#searchBar .rdp-dropdown{width: 75px;}
#searchBar .rdp-dropdown option:disabled{background: #d6d6d6;color: #a7a7a7;}

#searchBar .rdp-range_start ,#searchBar .rdp-range_end{background-color: #f0f0ff;}
#searchBar .button_group{display: flex;} */

/* 토글 전체 컨테이너 */
.toggle-container {width: 66px;height: 23px;background-color: #f0f0f0;border-radius: 30px;display: flex;align-items: center;position: relative;cursor: pointer;padding: 4px;z-index: 2;position: absolute;top: 11px;right: 20px;}
  /* 옵션 텍스트 스타일 */
  .toggle-option {flex: 1;text-align: center;font-size: 11px;font-weight: 500;color: #aaa;transition: color 0.3s ease;z-index: 1; /* 버튼 아래로 숨지 않도록 */}
  /* 활성화된 텍스트 스타일 */
  .toggle-option.active {color: #000;font-weight: bold;}
  /* 토글 버튼 */
  .toggle-button {width: 34px;height: 19px;background-color: white; border-radius: 30px;position: absolute;top: 0px;transition: transform 0.3s ease;margin: 2px;}
  .table-toggle-button.left { transform: translateX(-4px);} /* 버튼 왼쪽 */
  .table-toggle-button.right {transform: translateX(24px); } /* 버튼 오른쪽 */


/* 테이블 토글 */
.table-toggle-button input { display: none; }
.table-toggle-slider { margin: 0 auto; display: block; position: relative; width: 36px; height: 18px; border-radius: 20px; transition: background-color 0.3s; cursor: pointer; background: #ccc; }
.table-toggle-slider::before { content: ''; position: absolute; top: 2px; left: 2px; width: 14px; height: 14px; background-color: #fff; border-radius: 50%; transition: transform 0.3s; }
.table-toggle-button input:checked + .table-toggle-slider { background-color: #4caf50; }
.table-toggle-button input:checked + .table-toggle-slider::before { transform: translateX(18px); }
.table-toggle-button input:indeterminate + .table-toggle-slider { background-color: #ff9800; }
.table-toggle-button input:indeterminate + .table-toggle-slider::before { left: 50%; transform: translateX(-50%); }

.active-toggle-container, .modal_form_content .active-toggle-container{display: flex;border: 1px solid #ccc;overflow: hidden;width: fit-content;background-color: #f5f5f5;}
.active-toggle-button, .modal_form_content .active-toggle-button{flex: 1;padding: 3px 12px;font-size: 12px;border: none;outline: none;background: none;cursor: pointer;transition: all 0.3s ease;color: #888;min-width: 75px;}
.active-toggle-button.active, .modal_form_content .active-toggle-button.active{background-color: #e6f0ff; color: #007bff;  font-weight: bold;border: 1px solid #007bff; /* 활성화된 버튼 테두리 */}
.active-toggle-button.btn_disable.active, .modal_form_content .active-toggle-button.btn_disable.active{background-color: #ffe6e6;color: #ff2d55; border: 1px solid #ff2d55;}
.active-toggle-button:not(.active):hover, .modal_form_content .active-toggle-button:not(.active):hover{ background-color: #eee;
}
/* table */
.table_wrap {display: flex; height: 100%; width: 100%; gap:15px; overflow: hidden; flex: 1;}
.table_type1, .table_type_min{width: 100%; background: transparent; height: 100%; position: relative; overflow: hidden;}
.table_type1 > div.table_box, .table_type_min > div.table_box{overflow: hidden; height: 100%; overflow-x: auto;}
.paging_table > div.table_box{height:calc(100% - 45px)}
.table_type1 div.notAllowedTable, .table_type_min div.notAllowedTable{position: absolute;top: 0;left: 0;height: 100%;width: 100%;background: #c7c7c778;cursor: not-allowed;}
.table_type1 table {border-collapse: collapse;width: 100%;height: 100%; text-align: center;margin: 0;font-size: 14px;background: transparent;}
.table_type1 thead{display:block;background:#333;border-top-left-radius:10px; border-top-right-radius: 10px; }
/* .table_type1 tbody{overflow-y: scroll;height:593px;display:block} */
/* .table_type1 tbody{overflow-y: scroll;display:block;height: calc(100% - 35px);} */
.table_type1 tbody{overflow-y: scroll;display:block;height: calc(100% - 43px); background: #fff;}
.table_type1 tbody::-webkit-scrollbar{display: absolute;}
/* .table_type1 .disableTbody{cursor: not-allowed; background: #ececec;}
.table_type1 table .disableTbody tr.table-row:hover {background: #ececec;} */
.table_type1 table tr{display:table-row}
.table_type1 table tbody tr.active, .table_type1 table tbody tr.table-row:hover{background: #f0f8ff; font-weight: bold;}
.table_type1 table th:first-child, .table_type1 table td:first-child{min-width:28px}
.table_type1 th,
.table_type1 td {border-bottom: 1px solid #e2e2e2;}
.table_type1 td .empty_td{display: flex;align-items: center;justify-content: center; height: 100%;}
.table_type1 th {font-size: 1.1rem;color: #fff;padding: 6px 4px;font-weight: 600;line-height: 2.5rem;letter-spacing: -0.04em; }
.table_type1 td {color: #666;font-size: 11.5px;}
.table_type1 td span.name {margin-left: 5px;display: inline-block;padding: 3px 6px;border-radius: 4px;border: 1px solid #ddd;}
.table_type1 td span.name.type1 {background: #b23333;}
.table_type1 td span.name.pub {background: #f4b316;}
/* .table_type1 td p {display: inline-block;margin-right: 20px;} */
.table_type1 td p span {font-size: 11px;background: #eaecf5;padding: 2px 7px;color: #333;border-radius: 2px;margin-right: 5px;}
.table_type1 td .temporary_td{min-height: 38px;    word-break: break-word;display: flex;    align-items: center;}
.table_type1 td .temporary_td label{margin-right:10px}
.table_type1 td .temporary_td label input{margin-right:5px}
.table_type1 td input[type=text]{ border: 1px solid #d3d3d3; padding: 4px; width: 100%;}
.table_type1 td input:read-only{outline: none; cursor: pointer;  background: #e5e5e5;}
.table_type1 td .table_radio span{display:flex;gap: 5px}
.table_type1 td .table_button{padding: 4px 8px;display: flex;align-items: center;justify-content: center;background: rgb(23, 162, 184);color: rgb(255, 255, 255);border-radius: 3px;font-size: 12.5px;}
.table_type1 td .table_button:hover{background: #138496;}
.table_type1 td .table_button:disabled{background: #d9d9d9; color: #7f8c8d;}
input[type="radio"] { display: none; } 
input[type="radio"] + label { vertical-align: middle; color: #7f8c8d; } 
input[type="radio"] + label::before { content: ""; display: inline-block; width: 6px; height: 6px; margin-right: 5px; vertical-align: middle; border: 2px solid #ffffff; box-shadow: 0 0 0 1px #bdc3c7; border-radius: 50%; } 
label[for^="radio"]::before { border-radius: 50%; } 
input[type="radio"]:checked + label::before { background: #379aea; box-shadow: 0 0 0 2px #379aea; } 
input[type="radio"]:checked + label { color: #379aea; } 
input[type="radio"]:disabled + label::before { box-shadow: 0 0 0 2px #ecf0f1; } 
input[type="radio"]:disabled + label { color: #ecf0f1; }


/* .table_type1 table tbody tr:hover{background:#f6f7fb} */
.table_type1 .table_footer{display: flex; align-items: center; justify-content: space-between; border-top: 1px solid #e2e2e2; padding: 0 5px; height: 45px; background: #ffffff;    border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;}
.table_type1 .table_footer .footer_left {display: flex; align-items: center; height: 100%;}
.table_type1 .table_footer .footer_left > .custom-select-container{margin-right:10px}
.table_type1 .table_footer .footer_left span.emphasis{font-weight: bold; font-size: 14px;}

.table_type1 .table_footer .footer_left .custom-select-options{top:auto ;bottom:0}
.table_type1 .table_footer .perPage{display: flex; align-items: center; height:100%; padding: 0 10px 0 0; border-right: 1px solid #bbb; margin-right: 9px;}
.table_type1 .table_footer input{outline: none; width: 50px; padding: 5px; border: 1px solid #ddd;}
.table_type1 .table_footer input:read-only{cursor: pointer; color: #777; font-weight: 600;}
.table_type1 .table_footer .perPage label{margin-left: 3px;}
.table_type1 .table_footer input::-webkit-inner-spin-button {appearance: none;-moz-appearance: none; -webkit-appearance: none;}

/* table2 */
.table_type2 {width: 550px; background: #fff; border: 1px solid #ddd; height: 100%;}
.table_type2 table {border-collapse: collapse;width: 100%;height: 100%; text-align: center;margin: 0;font-size: 13px;background: #fff;}
.table_type2 thead{display:block}
.table_type2 tbody{overflow-y: scroll;display:block;height: 200px;}
.table_type2 table tr{display:table-row}
.table_type2 table td{padding: 6px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.table_type2 table thead th{padding: 4px 8px;}
.table_type2 table tbody tr{font-size: 10px;border-bottom: 1px dotted #ddd;}

/* table3 */
.table_type_min {border: 1px solid #ddd;border-radius: 5px;}
.table_type_min table {border-collapse: collapse;width: 100%;height: 100%; text-align: center;margin: 0;font-size: 14px;background: transparent;}
.table_type_min thead{display:block;background:#f1f1f1;border-top-left-radius:3px; border-top-right-radius: 3px; }
.table_type_min tbody{overflow-y: scroll;display:block;height: calc(100% - 31px); background: #fff;}
.table_type_min table tr{display:table-row}
.table_type_min table tbody tr.active, .table_type_min table tbody tr.table-row:hover{background: #e4e7f2;}
.table_type_min table th:first-child, .table_type_min table td:first-child{min-width:0px}
.table_type_min th, .table_type_min td {border-bottom: 1px solid #e2e2e2;}
.table_type_min td .empty_td{display: flex;align-items: center;justify-content: center; height: 100%;}
.table_type_min th {font-size: 1.1rem;color: #777;padding: 6px 4px;font-weight: 600;line-height: 1.5rem;letter-spacing: -0.04em; }
.table_type_min td {color: #666;font-size: 11.5px;}
.table_type_min td p span {font-size: 11px;background: #eaecf5;padding: 2px 7px;color: #333;border-radius: 2px;margin-right: 5px;}
.table_type_min td .temporary_td{min-height: 38px;    word-break: break-word;display: flex;    align-items: center;}
.table_type_min td .temporary_td label{margin-right:10px}
.table_type_min td .temporary_td label input{margin-right:5px}

/* reading_table */
#reading_table thead .th_box{display:flex; align-items: center; justify-content: center; white-space: nowrap; gap:3px; position: relative;}
#reading_table thead .th_box .column_input{ background: #e7e7e7;width: 99%;height: 24px; border-radius: 5px;padding: 0 4px;outline: 2px solid #a9a9a9;font-size: 12px;position: absolute;bottom: -30px;}
#reading_table thead .th_box .column_input::before{
  content: "";
  position: absolute;
  bottom: 100%; /* 입력 필드 상단에 위치 */
  left: 50%;
  transform: translateX(-50%) translateY(100%); /* 화살표를 완전히 상단에 위치시키기 */
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #FF6347;
}
#reading_table thead .th_box .column_icons{ display: flex;}
#reading_table thead .th_box .column_icons button.column_icon{ display: flex;height: 19px;width: 14px;padding: 1px;align-items: center;justify-content: center;}
#reading_table thead .th_box .column_icons button.column_icon .ic_default{width: 14px; height:14px;fill:#b1b1b4}
#reading_table thead .th_box .column_icons button.column_icon .ic_sort{width: 14px; height:14px;fill:#7ca2ff}
#reading_table tbody .block_element{display:block; border:none;height:100%;}
#reading_table .expanded_content_container {display: flex;   justify-content: space-between; padding: 20px; background-color: #f7f7f7; box-shadow: 0 -5px 5px -5px rgba(0,0,0,0.2);}
#reading_table .expanded_content{ flex: 1 1 0%;display: flex;flex-direction: column;gap: 10px;}

#reading_table .expand_content_box{display: grid;grid-template-columns: 80px 2fr;justify-items: start;row-gap: 10px; column-gap: 10px; font-size: 12px; padding: 5px; align-items: center;}
#reading_table .expand_content_box dt{font-weight: bold; color: #333; letter-spacing: 1px; }
#reading_table .expand_content_box dd {text-align: left; position: relative; display: flex;align-items: center; width: 100%; gap: 10px; }
#reading_table .expand_content_box dd span{flex : 1; padding: 5px; background-color: #FFf; font-size: 12px;}
#reading_table .expand_content_box dd textarea{resize: none; padding: 5px 20px 5px 5px;width: 100%;height: 60px; background: #fff; font-size: 12px;}
#reading_table .expand_content_box dd .copy_button{position: absolute; bottom: 1px; right: 1px; width: 24px; height: 24px; padding: 0; background: unset;     color: #569dff;}
#reading_table .expand_content_box dd .copy_button:hover{background-color: unset; color: #007BFF;}
#reading_table .expand_content_box dd .copy_button svg{width: 18px;height: 18px;}
#reading_table .expand_content_box input{padding: 3px; margin-right: 10px; border: 1px solid #ccc; border-radius: 4px;}
#reading_table .expand_content_box button {padding: 4px 12px; background-color: #606060; color: white; border: none; border-radius: 4px; cursor: pointer;}
#reading_table .expand_content_box button:hover { background-color: #0056b3; }

/* tabel.reading_table tbody .table_tr td{display:block; border:none;height:100%;} */
/* 병원 서치 리스트 */
/* .hosplist_search{flex-basis:17%;display:flex;flex-direction:column; flex-shrink: 0;gap:10px} */
.hosplist_search{flex-basis:15%;display:flex;flex-direction:column; flex-shrink: 0;gap:10px}
.hosplist_search .search_hospital_group
.hosplist_search .hosplist_search{height: 32px}
.hosplist_search svg{fill:#333}
.hosplist_search p{width: 100%;display: flex;align-items: center; background: #ffc80f;}
.hosplist_search p input{background: transparent; outline: none;}
.hosplist_search .search_group{display: flex;align-items: center;}
.hosplist_search p.input_groups{width: 100%;display: flex;align-items: center;border-radius:10px;padding:3px}
/* .hosplist_search p{width: 100%;display: flex;align-items: center;border:1px solid #ddd; background: #fff;} */
.hosplist_search .search_group{display: flex;align-items: center;}
.hosplist_search .search_group h4{ flex-basis: 12%; color: #333;}
.hosplist_search p.total{border-radius:10px;background:#2c356a;color:#fff;margin:0;display: flex; justify-content: space-between;line-height:32px;padding:2px 10px;}
.hosplist_search p.total em{font-size:16px;font-weight:700}
.hosplist_search input{width: 100%;font-size: 14px;padding: 9px; }
.hosplist_search ul{border:1px solid #ddd;flex: 1; overflow-y: scroll; background: #fff;border-radius:10px;}
.hosplist_search .custom-select-options{border: none;}
.hosplist_search ul li{display: flex; align-items: center;justify-content: space-between;padding: 8px; border-bottom: 1px dotted #eee;}
.hosplist_search ul li span{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.hosplist_search ul li span.label{flex: 1;}
.hosplist_search ul li span.list_label{width: 45%; overflow: hidden;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 40%;}
.hosplist_search ul li em.list_value{width: 55%; text-align: right;}
.hosplist_search ul li.active, .hosplist_search ul li:hover{background: #f2f2f2;cursor:pointer}


/* confirm */
.confirm-table{border-collapse: collapse;width: 100%;}
.confirm-table thead{border-bottom: 3px solid #008cba;}
.confirm-table thead th{padding: 2px 0;}
.confirm-table tbody tr{border-bottom: 1px dotted #ddd;}

::-webkit-scrollbar {width:7px;height: 7px;}
::-webkit-scrollbar-thumb {background:#c2c2c2; border-radius:5px;}
::-webkit-scrollbar-track {background:none;}
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* sign modal */
.sign_container{ text-align: center; padding: 20px 25px; width: 400px; margin: 0 auto; font-family: Arial, sans-serif; position: absolute; z-index: 5; background: #ececec; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; position: absolute;}
.sign_close_button{background:none; padding: 0; position:absolute; right: 7px; top: 7px;}
.sign_title { font-size: 16px; font-weight: bold; margin-bottom: 10px; }
.sign_subtitle { font-size: 12px; color: #666; margin-bottom: 10px; }
.sign_dropzone{ border: 2px dashed #cbd5e1; border-radius: 8px; padding: 10px; cursor: pointer; background-color: #f9fafb; position: relative; height: 200px; display: flex; justify-content: center; align-items: center; flex-direction: column; background-size: contain; background-position: center; background-repeat: no-repeat; margin-bottom: 10px; }
.sign_icon { width: 40px; height: 40px; margin-bottom: 10px; }
.sign_explane{ font-size: 11px;  margin-top: 5px;}
.sign_button { display: inline-block; background-color: #5a8eff; color: #fff; padding: 7px 14px; border-radius: 5px; cursor: pointer; margin-top: 10px; }
.sign_hiddenInput { display: none; }
.sign_error { color: red; font-size: 12px; margin-top: 10px; }
.sign_fileInfo { margin-top: 20px; text-align: left; background-color: #f1f5f9; padding: 10px; border-radius: 5px; }

/* drag drop */
.dnd_container{display: flex; gap: 15px; height:300px}
.dnd_content_box{flex: 1;border: 1px solid #ddd;padding: 8px; background: #f9f9f9;}
.dnd_content_box.target{border: 1px solid #bedeff; background: #eaf4ff;}
.dnd_content_title{font-size: 14px;color: #333;margin-bottom: 11px;}
.dnd_count{font-weight:bold; color: #007BFF;}
.dnd_list{display: flex;flex-direction: column;overflow-y: scroll;height: calc(100% - 29px); padding: 0 4px;}
.dnd_list_item{padding: 8px;margin-bottom: 10px;cursor: grab;background: rgb(255, 255, 255); gap:5px; display: flex;align-items: center;box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 5px 0px}
.dnd_list_item.selected {background-color: #eaf4ff;border: 1px solid #007BFF;}
.dnd_list_item.active{opacity: 0.7;border: 1px dashed #007BFF;}
.dnd_list_item:hover{background-color: #f0f0f0;border-color: #007BFF;}
.dnd_list_item_label{margin: 0;}
.dnd_button_group {display: flex;flex-direction: column;justify-content: center;align-items: center;gap: 10px;}
.dnd_button_group button {display: flex;align-items: center;gap: 5px;padding: 6px 8px;font-size: 12px;font-weight: bold;background-color: #409cff;color: #fff;border: none;border-radius: 2px;cursor: pointer;transition: background-color 0.3s ease;}
.dnd_button_group button:hover {background-color: #4081c7;}
.dnd_button_group button:disabled {background-color: #ccc;cursor: not-allowed;}
.dnd_button_group button svg {width: 16px;height: 16px}